import React, { useEffect, useState } from 'react';
import Main from '../components/template/Main';
import { useDispatch, useSelector } from 'react-redux';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ListStands from '../components/stands/ListStands';
import TemplateApp from '../components/template/TemplateApp';
import EventsServices from '../services/EventsServices';
import { useHistory } from 'react-router-dom';

const FairsList = (props) => {
  const auth = useSelector((state) => state.auth);
  const email = auth && auth.email ? auth.email : null;
  const ticketId = useSelector((state) => state.navigator.ticketId);
  const [roomsFair, setRoomsFair] = useState([]);
  const [managerEvents, setManagerEvents] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.tickets.some((ticket) => ticket.isOwner === true)) {
        history.push('/home');
        return;
      }

      const result = await dispatch(
        EventsServices.findRoomFair({
          eventId: props.eventId,
          ticketId,
          email,
        })
      );

      if (result && result.roomsFair && result.roomsFair.length > 0) {
        const stand = result.roomsFair.filter(
          (item) => item.typeEvent === 'access_fairs'
        );
        setRoomsFair(stand ? stand : []);
        setManagerEvents(result.managerEvents);
      }
    };

    if (!props.preview && !managerEvents && roomsFair.length <= 0 && email) {
      fetchData().catch();
    }
  }, [
    dispatch,
    props,
    managerEvents,
    email,
    ticketId,
    roomsFair,
    auth.tickets,
    history,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(
        EventsServices.findEventsPreview(props.eventId, history)
      );
      if (result && result.roomsFair && result.roomsFair.length > 0) {
        const stand = result.roomsFair.filter(
          (item) => item.typeEvent === 'access_fairs'
        );
        setRoomsFair(stand ? stand : []);
        setManagerEvents(result.managerEvents);
      }
    };

    if (props.preview && roomsFair.length <= 0) {
      fetchData().catch();
    }
  }, [dispatch, ticketId, props, history, roomsFair]);

  return (
    <TemplateApp>
      <Main>
        <div className="row no-gutters">
          <div className="col-lg-12 col-md-12 m-auto">
            {managerEvents && managerEvents.urlLobbyFair ? (
              <img
                src={managerEvents.urlLobbyFair}
                className="image-banner position-absolute"
                alt=""
              />
            ) : null}
            <div className="container">
              <div className="row">
                <div className="col-12 carousel-center">
                  {roomsFair.length > 0 && (
                    <ListStands roomsFair={roomsFair} preview={props.preview} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </TemplateApp>
  );
};

export default FairsList;
