import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventsServices from '../services/EventsServices';
import TemplateApp from '../components/template/TemplateApp';
import VideoRoom from '../components/notification/VideoRoom';
import { Link } from 'react-router-dom';
import Interatividades from '../components/interatividades/Interatividades';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const RoomEvents = (props) => {
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const [roomsFair, setRoomsFair] = useState(null);

  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);

  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const [roomsCount, setRoomsCount] = useState();
  const [tabsAtivas, setTabsAtivas] = useState([]);

  const populateVideoOptions = () => {
    if (roomsFair) {
      let selectHtml = '';
      let json = null;
      try {
        json = JSON.parse(roomsFair.urlStreaming);
      } catch (error) {
        json = null;
      }
      if (json) {
        json.forEach((e, index) => {
          selectHtml +=
            '<option value="' + index + '">' + unescape(e.name) + '</option>';
        });
        if (json.length > 1) {
          return (
            <div className="pull-right form-inline">
              <label htmlFor="#playerSelect">Opções:</label>
              <select
                id="playerSelect"
                className="form-control"
                onChange={(e) => {
                  setCurrentVideoIndex(e.target.value);
                }}
                dangerouslySetInnerHTML={{
                  __html: selectHtml,
                }}
              ></select>
            </div>
          );
        }
      }
    }
  };

  const getStreamingUrl = (urlStreaming) => {
    let json = null;
    try {
      json = JSON.parse(urlStreaming);
    } catch (error) {
      return unescape('URL inválida');
    }

    if (json && json.length > 0) {
      return unescape(json[currentVideoIndex].streaming);
    } else {
      return unescape('URL inválida');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.tickets.some((ticket) => ticket.isOwner === true)) {
        history.push('/home');
        return;
      }

      const itemRoomFair = await EventsServices.findRoomsFairType(
        auth.roomsFair,
        props.roomFairId,
        'access_room'
      );

      if (itemRoomFair) {
        setRoomsFair(itemRoomFair);
      } else {
        const privateRoom = await dispatch(
          EventsServices.findPrivateRoom({
            roomId: props.roomFairId,
            eventId: auth.eventId,
          })
        );
        setRoomsFair(privateRoom.data.payload);
      }
    };

    fetchData();
  }, [next, previous, auth, props.roomFairId, dispatch, history]);

  useEffect(() => {
    if (
      roomsFair &&
      (roomsFair.dados_interatividade ||
        JSON.parse(roomsFair.urlShopbanner).length > 0)
    ) {
      if (JSON.parse(roomsFair.urlShopbanner).length > 0) {
        JSON.parse(roomsFair.urlShopbanner).active = true;

        const newDados = [
          ...JSON.parse(roomsFair.dados_interatividade),
          { type: 'produtos', active: true },
        ];
        setTabsAtivas(
          newDados.filter(function (data) {
            return data.active === true;
          })
        );
      } else {
        setTabsAtivas(
          JSON.parse(roomsFair.dados_interatividade).filter(function (data) {
            return data.active === true;
          })
        );
      }
    }
  }, [roomsFair]);

  useEffect(() => {
    const idList = auth.roomsFair.reduce((idsArray, item) => {
      if (item.typeEvent === 'access_room') {
        for (let key in item) {
          if (key === 'roomFairId') {
            if (
              !item.ocultar_sala ||
              item.roomFairId.toString() === props.roomFairId
            ) {
              idsArray.push(item[key]);
            }
          }
        }
      }
      setRoomsCount(idsArray.length);
      return idsArray;
    }, []);

    const prev = idList.indexOf(parseInt(props.roomFairId)) - 1;
    const prox = idList.indexOf(parseInt(props.roomFairId)) + 1;

    setPrevious(prev >= 0 ? idList[prev] : null);
    setNext(prox < idList.length ? idList[prox] : null);
  }, [auth, props]);

  return roomsFair ? (
    <TemplateApp videoRoom={true}>
      <div className="row videoRoomRow">
        <div
          className={
            tabsAtivas.length > 0
              ? 'col-12 p-0 col-lg-8'
              : 'col-12 p-0 col-lg-12 roomFullRow'
          }
          id="videoRoomCol"
          style={{ backgroundColor: 'var(--background-color)' }}
        >
          <center>
            {roomsCount > 1 && !roomsFair.is_reserved_id ? (
              <div
                className="row m-0 mt-2"
                style={{
                  height: '10%',
                  width: '90%',
                  justifyContent: 'space-between',
                }}
              >
                <>
                  <Link
                    to={'/room/' + previous}
                    className="btn btn-dark"
                    title="Anterior"
                    onClick={(e) => {
                      const result = EventsServices.findRoomsFairType(
                        auth.roomsFair,
                        previous,
                        'access_room'
                      );
                      if (result) {
                        setRoomsFair(result ? result : null);
                      } else {
                        e.preventDefault();
                      }
                    }}
                    disabled={previous === null}
                  >
                    Anterior
                  </Link>
                  <Link
                    to={'/room/' + next}
                    className="btn btn-dark pull-right"
                    title="Próximo"
                    onClick={(e) => {
                      const result = EventsServices.findRoomsFairType(
                        auth.roomsFair,
                        next,
                        'access_room'
                      );
                      if (result) {
                        setRoomsFair(result ? result : null);
                      } else {
                        e.preventDefault();
                      }
                    }}
                    disabled={next === null}
                  >
                    Próximo
                  </Link>
                </>
              </div>
            ) : null}
          </center>

          <div className="row pt-2 justify-content-center video-top-row m-0">
            <VideoRoom
              streamUrl={roomsFair && getStreamingUrl(roomsFair.urlStreaming)}
              roomName={roomsFair && roomsFair.roomFairName}
              index={currentVideoIndex}
              type={roomsFair && roomsFair.formato}
              hackathon_data={roomsFair && roomsFair.hackathon_data}
              auth={auth}
              roomId={props.roomFairId}
              setLoaded={setLoaded}
              tabsAtivas={
                JSON.parse(roomsFair.urlShopbanner).length > 0 ||
                JSON.parse(roomsFair.dados_interatividade).filter(function (data) {
                  return data.active === true;
                }).length > 0
              }
            />
          </div>

          <div className="row video-bottom-row ml-2 mr-0">
            <div className="col-8">
              <h5>{roomsFair !== null ? roomsFair.roomFairName : ''}</h5>
            </div>
            <div className="col-4">
              {loaded ? (
                <span
                  className="btn btn-dark pull-right ml20 hide"
                  onClick={() => {
                    loaded ? window.iniciarPIP() : console.log('notLoaded');
                  }}
                >
                  Picture in Picture
                </span>
              ) : null}
              {populateVideoOptions()}
            </div>
          </div>
        </div>
        {tabsAtivas.length > 0 ? (
          <div className="eventInteractiveHolder col-12 col-lg-4 p-0">
            <Interatividades
              dadosInteratividade={roomsFair.dados_interatividade}
              roomsFair={roomsFair}
            />
          </div>
        ) : null}
      </div>
    </TemplateApp>
  ) : null;
};

export default RoomEvents;
