import React, { useEffect, useState } from 'react';
import Main from '../components/template/Main';
import { useDispatch, useSelector } from 'react-redux';
import EventsServices from '../services/EventsServices';
import TemplateApp from '../components/template/TemplateApp';
import { removeItem } from '../store/cart/CartReducer';
import { removeRemoveAllItem } from '../store/cart/CartItemReducer';
import { useHistory } from 'react-router-dom';
import CouponDiscount from '../components/cart/CouponDiscount';
import CartEmpty from '../components/cart/CartEmpty';
import CartItemList from '../components/cart/CartItemList';
import { addMessage } from '../store/messages/MessagesReducer';
import PaymentDiscountFree from '../components/cart/PaymentDiscountFree';
import AuthServices from '../services/AuthServices';
import { loadStripe } from '@stripe/stripe-js';

const ShoppingFinish = (props) => {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const formas_pagamento = JSON.parse(
    JSON.parse(localStorage.getItem('@navigator')).formas_pagamento
  );
  const eventId = useSelector((state) => state.navigator.eventId);
  const [paymentDiscountFree, setPaymentDiscountFree] = useState(false);
  const [cartItem, setCartItem] = useState(useSelector((state) => state.cartItem));

  const dispatch = useDispatch();
  const history = useHistory();

  const onChangeValues = (purchaseItems, ticketId, quantity, isRemoved) => {
    const itemChange = EventsServices.onChangeValues(
      purchaseItems,
      ticketId,
      quantity,
      isRemoved
    );
    setCartItem(itemChange);
    dispatch(EventsServices.updateCartItem(itemChange, ticketId, isRemoved));
  };

  const setDiscountCoupon = (discountCoupon) =>
    dispatch(EventsServices.setCouponDiscount(cart, discountCoupon));

  const CheckoutDisplay = () => (
    <button className="btn btn-orange mb-2" type="submit" onClick={toStripeCheckout}>
      Finalizar
    </button>
  );

  const toStripeCheckout = async () => {
    if (!cartItem || cartItem.length <= 0) {
      dispatch(
        addMessage({
          message: 'Nenhum item adicionado no carrinho!',
          level: 'warning',
        })
      );
      return;
    }

    const discountCoupon = cart && cart.discountCoupon ? cart.discountCoupon : null;

    const result = await dispatch(
      EventsServices.finishCartItem(eventId, {
        purchaseItems: cartItem,
        discountCoupon,
      })
    );

    let formOfPayment = [];

    if (formas_pagamento.billet && isPermittedBillet()) {
      formOfPayment.push('BILLET');
    }
    if (formas_pagamento.credit_card) {
      formOfPayment.push('CARD_CREDIT');
    }

    if (result) {
      const cartData = { ...cart, discountCoupon };
      cartData.purchaseItems = cartItem;
      if (cartItem.value <= 0) {
        cartData.eventFree = true;
        formOfPayment = 'FREE';
      }

      console.log('cupom1', discountCoupon, 'cupom2', cartData.discountCoupon);

      const stripeSessionId = await dispatch(
        EventsServices.toStripeCheckout({
          cart: {
            eventFree: cartData.eventFree,
            eventId: cartData.eventId,
            purchaseItems: cartData.purchaseItems,
            discountCoupon: cartData.discountCoupon,
          },
          participantId: auth.participantId,
          formOfPayment,
        })
      );

      const stripePublicKey = process.env.REACT_APP_STRIPE_PK;

      const stripe = await loadStripe(stripePublicKey);

      stripe.redirectToCheckout({ sessionId: stripeSessionId });
    }
  };

  const finishFreeEvent = async (data, formOfPayment) => {
    if (!cartItem || cartItem.length <= 0) {
      dispatch(
        addMessage({
          message: 'Nenhum item adicionado no carrinho!',
          level: 'warning',
        })
      );
      return;
    }

    const discountCoupon = cart && cart.discountCoupon ? cart.discountCoupon : null;

    const result = await dispatch(
      EventsServices.finishCartItem(eventId, {
        purchaseItems: cartItem,
        discountCoupon,
      })
    );

    if (result) {
      const cartData = { ...cart, discountCoupon };
      cartData.purchaseItems = cartItem;
      if (cartItem.value <= 0) {
        cartData.eventFree = true;
        formOfPayment = 'FREE';
      }

      /* data.amount = cartItem.value; */
      await dispatch(
        EventsServices.finishFreeEvent(
          {
            ...data,
            cart: {
              eventFree: cartData.eventFree,
              eventId: cartData.eventId,
              purchaseItems: cartData.purchaseItems,
              discountCoupon: cartData.discountCoupon,
            },
            participantId: auth.participantId,
            formOfPayment,
          },
          setDiscountCoupon,
          history,
          auth.email
        )
      );
    }
  };

  useEffect(() => {
    if (cart && cartItem && cartItem.length > 0 && cart.discountCoupon) {
      const dataValues = EventsServices.calculateCartValue(
        cartItem,
        cart.discountCoupon
      );
      setPaymentDiscountFree(
        dataValues &&
          dataValues.valueBuy === dataValues.valueDiscount &&
          dataValues.valueTotal === 0
      );
    } else {
      if (cart && cartItem && cartItem.length > 0) {
        if (cart.discountCoupon === null) {
          const dataValues = EventsServices.calculateCartValue(cartItem, null);
          if (dataValues.valueTotal === 0) {
            setPaymentDiscountFree(true);
          }
        }
      } else {
        setPaymentDiscountFree(false);
      }
    }
  }, [cart, cartItem]);

  const applyDiscountCoupon = async (e) => {
    e.preventDefault();
    const discountCoupon = cart && cart.discountCoupon ? cart.discountCoupon : null;

    const result = await dispatch(
      EventsServices.findDiscountCoupon(
        eventId,
        discountCoupon.coupon,
        setDiscountCoupon
      )
    );

    if (result) {
      setDiscountCoupon(result);
    }
  };

  const cancelOrder = async () => {
    dispatch(removeItem());
    dispatch(removeRemoveAllItem());
    if (
      auth &&
      auth.purchase &&
      auth.purchase.length > 0 &&
      auth.managerEvents &&
      auth.tickets &&
      auth.tickets.length > 0
    ) {
      await dispatch(
        EventsServices.accessTicketEvent(
          auth.email,
          auth.eventId,
          auth.tickets,
          history
        )
      );
      return;
    }
    history.push('/home');
  };

  const isPermittedBillet = () => {
    if (cart && cart.dateStart) {
      const dateEvent = new Date(cart.dateStart);
      const dateExpired = new Date(dateEvent.setDate(dateEvent.getDate() - 5));

      if (new Date() > dateExpired) {
        return false;
      }
    }
    return true;
  };

  return (
    <TemplateApp>
      <Main>
        <button
          id="reloadBtn"
          className="d-none"
          onClick={() => {
            dispatch(AuthServices.reloadData(auth, history));
          }}
        >
          Reload
        </button>

        {formas_pagamento.billet || formas_pagamento.credit_card ? (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mt-5">
                  <h3 className="text-dark">
                    <strong>Carrinho de compra</strong>
                  </h3>
                </div>

                <div className="border-shadow bg-white">
                  {cartItem ? (
                    <>
                      <CartItemList
                        {...props}
                        purchaseItems={cartItem}
                        onChangeValues={onChangeValues}
                      />

                      <div className="col-12 p-5">
                        <CouponDiscount
                          cartItem={cartItem}
                          discountCoupon={
                            cart && cart.discountCoupon ? cart.discountCoupon : null
                          }
                          setDiscountCoupon={setDiscountCoupon}
                          applyDiscountCoupon={applyDiscountCoupon}
                        />
                      </div>

                      <div className="col-10 ml-5 mb-5">
                        <strong>
                          <p>Falta pouco para você finalizar sua compra!</p>
                        </strong>
                        <p>
                          Após escolher o tipo de ingresso e quantidade, escolha a
                          forma de pagamento e inclua o código do seu cupom de
                          desconto, se houver.
                        </p>
                        <p>
                          Preencha todos os campos, eles são obrigatórios. Você
                          poderá realizar o pagamento via cartão de crédito ou boleto
                          bancário. Caso escolha boleto, preste atenção à data de
                          vencimento!
                        </p>

                        <p>
                          <strong>IMPORTANTE:</strong> Compras de mais de 01 ingresso
                          Após confirmação do pagamento, você terá no seu menu
                          Ingressos, um código de identificação para cada ingresso.
                        </p>
                        <p>
                          Ative o seu ingresso em Acessar Evento. Informe a ID dos
                          demais ingressos aos outros participantes do seu grupo.
                        </p>
                        <p>
                          Aqui mesmo, nesse portal, <strong>ao se cadastrar</strong>,
                          eles utilizarão a ID do ingresso para Acessar ao Evento.
                        </p>
                      </div>
                      {!paymentDiscountFree && formas_pagamento ? (
                        CheckoutDisplay ? (
                          <div className="col-lg-12 col-md-12 p-2 mt-4">
                            <div
                              className="row"
                              style={{ justifyContent: 'flex-end' }}
                            >
                              <div className="col-12 col-lg-4">
                                <CheckoutDisplay />
                              </div>
                            </div>
                          </div>
                        ) : null
                      ) : (
                        <div className="col-12 pb-5 pl-5 pr-5">
                          <PaymentDiscountFree
                            finishFreeEvent={finishFreeEvent}
                            cancelOrder={cancelOrder}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <CartEmpty />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row justify-content-center mt-5">
              <h3>Compras de ingresso indisponível.</h3>
            </div>
          </div>
        )}
      </Main>
    </TemplateApp>
  );
};

export default ShoppingFinish;
