import React, { useEffect, useState } from 'react';
import Main from '../components/template/Main';
import TemplateApp from '../components/template/TemplateApp';
import UserServices from '../services/UserServices';
import { setLoading } from '../store/loading/LoadingReducer';
import { useDispatch, useSelector } from 'react-redux';
import { maskName } from '../utils/MaskInput';
import UploadImage from '../components/upload/UploadImage';
import Avatar from 'react-avatar';
import NetworkServices from '../services/NetworkServices';
import EventsServices from '../services/EventsServices';
import NetworkInterestAreas from '../components/network/NetworkInterestAreas';
import { useHistory } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import NetworkCategorias from '../components/network/NetworkCategorias';

const Network = (props) => {
  const history = useHistory();
  const titulo_areasInteresse = useSelector(
    (state) => state.navigator.titulo_areasInteresse
  );
  const auth = useSelector((state) => state.auth);
  const [networkParticipant, setNetworkParticipant] = useState({
    name: '',
    company: '',
    responsibility: '',
    networkMobile: '',
    avatarImage: process.env.PUBLIC_URL + '/static/imgs/user.svg',
    linkedinUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    areaInterest: [],
    participantId: auth.participantId,
    nomeMenu: '',
    mini_bio: '',
  });
  const [interestAreas, setinterestAreas] = useState({
    areaInterest: [],
  });

  const [categorias_network, setCategorias_network] = useState({
    categorias_network: [],
  });

  const [loadedData, setLoadedData] = useState(false);

  const [selectedInterestAreas, setSelectedInterestAreas] = useState([]);
  const [selectedCategorias_network, setSelectedCategorias_network] = useState([]);
  const dispatch = useDispatch();
  const [uploadImage, setUploadImage] = useState({ file: '', imageUrl: '' });

  const submitImage = async () => {
    if (uploadImage.file) {
      const fileParts = uploadImage.file.name.split('.');
      const fileType = fileParts[1];

      const urlFile = await dispatch(
        UserServices.uploadPhoto(fileType, uploadImage.file)
      );

      if (urlFile && urlFile !== '') {
        return urlFile;
      }

      return '';
    }

    return networkParticipant.avatarImage;
  };

  const addInterestAreas = (item) => {
    if (selectedInterestAreas.find((f) => f === item)) {
      setSelectedInterestAreas((object) => object.filter((f) => f !== item));
    } else {
      setSelectedInterestAreas((object) => object.concat(item));
    }
  };

  const addCategorias_network = (item) => {
    if (selectedCategorias_network.find((f) => f === item)) {
      setSelectedCategorias_network((object) => object.filter((f) => f !== item));
    } else {
      setSelectedCategorias_network((object) => object.concat(item));
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const urlFile = await submitImage();

    const dataInput = {
      ...networkParticipant,
      avatarImage: urlFile,
      eventId: auth.eventId,
      areaInterest: selectedInterestAreas,
      categorias_network: selectedCategorias_network,
    };

    await dispatch(NetworkServices.editNetwork(dataInput, history));
  };

  const returnEmptyIfNull = (field) => {
    return field && field !== null ? field : '';
  };

  const returnEmptyArrayIfNull = (field) => {
    return field && field !== null ? JSON.parse(field) : [];
  };

  const init = () => {
    const fetchData = async () => {
      if (!auth.tickets.some((ticket) => ticket.isOwner === true)) {
        history.push('/home');
        return;
      }

      const resultUser = await dispatch(UserServices.findUserProfile(auth.eventId));
      const eventData = await dispatch(EventsServices.findEvents());

      if (resultUser && resultUser.networkName !== null && !props.inside) {
        history.push('/network/participant/' + auth.eventId);
        return;
      }

      if (eventData) {
        setinterestAreas({
          ...interestAreas,
          areaInterest: eventData.interestAreas,
        });

        setCategorias_network({
          ...categorias_network,
          categorias_network: eventData.categorias_network,
        });
      }

      if (resultUser) {
        setNetworkParticipant({
          ...networkParticipant,
          name: returnEmptyIfNull(resultUser.name),
          avatarImage:
            returnEmptyIfNull(resultUser.avatar) === ''
              ? networkParticipant.avatarImage
              : returnEmptyIfNull(resultUser.avatar),
          networkMobile: returnEmptyIfNull(resultUser.phone),
          company: returnEmptyIfNull(resultUser.empresa),
          mini_bio: returnEmptyIfNull(resultUser.mini_bio),
          responsibility: returnEmptyIfNull(resultUser.cargo),
          linkedinUrl: returnEmptyIfNull(resultUser.urlLinkedin),
          facebookUrl: returnEmptyIfNull(resultUser.urlFacebook),
          twitterUrl: returnEmptyIfNull(resultUser.urlTwitter),
          instagramUrl: returnEmptyIfNull(resultUser.urlInstagram),
          areaInterest: returnEmptyArrayIfNull(resultUser.areasInteresse),
          categorias_network: returnEmptyArrayIfNull(resultUser.categorias_network),
          nomeMenu:
            eventData.nome_menu_network !== null
              ? eventData.nome_menu_network
              : 'Módulo de Network',
        });

        returnEmptyArrayIfNull(resultUser.areasInteresse).forEach((item) => {
          addInterestAreas(item);
        });

        returnEmptyArrayIfNull(resultUser.categorias_network).forEach((item) => {
          addCategorias_network(item);
        });
      }
    };

    if (auth.participantId && interestAreas.areaInterest <= 0) {
      fetchData().catch(() => dispatch(setLoading(false)));
    }

    if (auth.participantId && categorias_network.categorias_network <= 0) {
      fetchData().catch(() => dispatch(setLoading(false)));
    }
    setLoadedData(true);
  };

  useEffect(init, [
    dispatch,
    auth,
    history,
    interestAreas,
    categorias_network,
    networkParticipant,
    props.inside,
  ]);

  const returnNetwork = (inside) => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className={
                inside
                  ? 'card-form border-shadow-nt mt-0 inside'
                  : 'card-form border-shadow mt-0'
              }
            >
              <div className="m-1 mb-lg-4 md-md-2">
                <div className="card-body p-0">
                  <h5 className="card-title text-left text-color-custom">
                    <strong>{networkParticipant.nomeMenu}</strong>
                  </h5>
                  <h6>
                    <strong className="text-muted">
                      Para acessar este módulo, precisamos de mais informações no seu
                      cadastro.
                    </strong>
                  </h6>
                </div>
              </div>

              <hr className="hr-custom" />

              <div className="m-1 md-md-2">
                <div className="card-body p-0">
                  <form onSubmit={handleSubmitForm}>
                    <div className="row no-gutters font-weight-bold">
                      <div className="col-md-10 p-2 font-weight-bold">
                        <div className="form-label-group ">
                          <label>Imagem atual</label>
                          <br />
                          <Avatar
                            googleId="118096717852922241760"
                            size="80"
                            round={true}
                            src={networkParticipant.avatarImage}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row no-gutters font-weight-bold">
                      <div className="col-md-10 p-2 font-weight-bold">
                        <div className="form-label-group ">
                          <label>Modificar minha Imagem atual</label>
                          <UploadImage setUpload={setUploadImage} />
                        </div>
                      </div>
                    </div>

                    <div className="row no-gutters font-weight-bold">
                      <div className="col-md-6 col-lg-6 p-2">
                        <div className="form-label-group">
                          <label htmlFor="name">Confirme seu nome completo</label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            maxLength="100"
                            className="form-control"
                            placeholder="Nome"
                            value={maskName(networkParticipant.name)}
                            onChange={(e) =>
                              setNetworkParticipant({
                                ...networkParticipant,
                                name: maskName(e.target.value),
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-lg-5 p-2">
                        <div className="form-label-group">
                          <label htmlFor="company">Onde você trabalha?</label>
                          <input
                            type="text"
                            id="company"
                            name="company"
                            maxLength="75"
                            className="form-control"
                            placeholder="Sua empresa"
                            value={maskName(networkParticipant.company)}
                            onChange={(e) =>
                              setNetworkParticipant({
                                ...networkParticipant,
                                company: maskName(e.target.value),
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row no-gutters font-weight-bold">
                      <div className="col-md-6 col-lg-6 p-2">
                        <div className="form-label-group">
                          <label htmlFor="networkMobile">
                            Confirme seu número de celular
                          </label>
                          <PhoneInput
                            defaultCountry="BR"
                            placeholder="Celular"
                            value={networkParticipant.networkMobile}
                            onChange={(data) => {
                              setNetworkParticipant({
                                ...networkParticipant,
                                networkMobile: data,
                              });
                            }}
                            id="networkMobile"
                            name="networkMobile"
                            className="form-control"
                            maxLength="20"
                          />
                          <small className="text-muted">
                            Seu número será compartilhado para conversas no WhatsApp.
                          </small>
                        </div>
                      </div>

                      <div className="col-md-3 col-lg-5 p-2">
                        <div className="form-label-group">
                          <label htmlFor="responsibility">
                            Qual sua função nessa empresa?
                          </label>
                          <input
                            type="text"
                            id="responsibility"
                            name="responsibility"
                            className="form-control"
                            placeholder="Seu cargo"
                            maxLength="75"
                            value={maskName(networkParticipant.responsibility)}
                            onChange={(e) =>
                              setNetworkParticipant({
                                ...networkParticipant,
                                responsibility: maskName(e.target.value),
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-11  p-2">
                        <div className="form-label-group">
                          <label htmlFor="mini-bio">Mini bio</label>
                          <textarea
                            id="mini-bio"
                            name="mini-bio"
                            className="form-control"
                            placeholder="Conte mais sobre você"
                            maxLength="300"
                            value={networkParticipant.mini_bio}
                            onChange={(e) =>
                              setNetworkParticipant({
                                ...networkParticipant,
                                mini_bio: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row no-gutters font-weight-bold">
                      <div className="col-md-6 col-lg-6 p-2">
                        <label>Compartilhe seu perfil nas redes sociais</label>
                      </div>
                    </div>

                    <div className="row no-gutters font-weight-bold">
                      <div className="col-md-6 col-lg-6 p-2">
                        <div className="form-label-group">
                          <label htmlFor="networkMobile">Facebook</label>
                          <input
                            type="text"
                            id="facebookUrl"
                            name="facebookUrl"
                            className="form-control"
                            maxLength="255"
                            placeholder="https://"
                            value={networkParticipant.facebookUrl}
                            onChange={(e) =>
                              setNetworkParticipant({
                                ...networkParticipant,
                                facebookUrl: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-lg-5 p-2">
                        <div className="form-label-group">
                          <label htmlFor="responsibility">Instagram</label>
                          <input
                            type="text"
                            id="instagramUrl"
                            name="instagramUrl"
                            className="form-control"
                            placeholder="https://"
                            maxLength="255"
                            value={networkParticipant.instagramUrl}
                            onChange={(e) =>
                              setNetworkParticipant({
                                ...networkParticipant,
                                instagramUrl: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <script></script>

                    <div className="row no-gutters font-weight-bold">
                      <div className="col-md-6 col-lg-6 p-2">
                        <div className="form-label-group">
                          <label htmlFor="networkMobile">Linkedin</label>
                          <input
                            type="text"
                            id="linkedinUrl"
                            name="linkedinUrl"
                            className="form-control"
                            maxLength="255"
                            placeholder="https://"
                            value={networkParticipant.linkedinUrl}
                            onChange={(e) =>
                              setNetworkParticipant({
                                ...networkParticipant,
                                linkedinUrl: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-lg-5 p-2">
                        <div className="form-label-group">
                          <label htmlFor="responsibility">X</label>
                          <input
                            type="text"
                            id="twitterUrl"
                            name="twitterUrl"
                            className="form-control"
                            placeholder="https://"
                            maxLength="255"
                            value={networkParticipant.twitterUrl}
                            onChange={(e) =>
                              setNetworkParticipant({
                                ...networkParticipant,
                                twitterUrl: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row no-gutters font-weight-bold">
                      {interestAreas.areaInterest.length > 1 && (
                        <>
                          {' '}
                          <div className="col-12 p-2">
                            <label className="mb-0">
                              {titulo_areasInteresse
                                ? titulo_areasInteresse
                                : 'Quais são suas áreas de interesse?'}
                            </label>
                          </div>
                          <div className="col-12 p-2" id="interestAreas">
                            <NetworkInterestAreas
                              interestAreas={interestAreas}
                              addInterestAreas={addInterestAreas}
                              selectedInterestAreas={selectedInterestAreas}
                            />
                          </div>
                        </>
                      )}
                      {categorias_network.categorias_network.length > 1 && (
                        <>
                          <div className="col-12 p-2">
                            <label className="mb-0">
                              Quais são suas categorias?
                            </label>
                          </div>
                          <div className="col-12 p-2" id="categorias_network">
                            <NetworkCategorias
                              categorias_network={categorias_network}
                              addCategorias_network={addCategorias_network}
                              selectedCategorias_network={selectedCategorias_network}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="row no-gutters">
                      <div className="col-2 p-2">
                        <button
                          className="btn btn-lg btn-blue btn-default text-uppercase"
                          type="submit"
                        >
                          {inside ? 'Salvar' : 'Acessar'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loadedData) {
    return props.inside ? (
      returnNetwork(props.inside)
    ) : (
      <TemplateApp>
        <Main>{returnNetwork(props.inside)}</Main>
      </TemplateApp>
    );
  } else {
    return null;
  }
};

export default Network;
