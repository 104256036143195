import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventsServices from '../services/EventsServices';
import TemplateApp from '../components/template/TemplateApp';
import { existUrl } from '../utils/Ultis';
import LogServices from '../services/LogServices';
import { useHistory, Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import InteratividadesStands from '../components/interatividades/InteratividadesStands';

const FairEvents = (props) => {
  const auth = useSelector((state) => state.auth);
  const [roomsFair, setRoomsFair] = useState(null);

  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleResize = () => {
    var ImageMap = function () {
        var n,
          areas = document.getElementsByTagName('area'),
          len = areas.length,
          coords = [],
          previousWidth = document.getElementById('standImg').naturalWidth;
        for (n = 0; n < len; n++) {
          coords[n] = areas[n].coords.split(',');
        }
        this.resize = function () {
          var n,
            m,
            clen,
            x = document.getElementById('standImg').offsetWidth / previousWidth;
          for (n = 0; n < len; n++) {
            clen = coords[n].length;
            for (m = 0; m < clen; m++) {
              coords[n][m] *= x;
            }
            areas[n].coords = coords[n].join(',');
          }
          previousWidth = document.getElementById('standImg').offsetWidth;
          return true;
        };
        window.onresize = this.resize;
      },
      imageMap = new ImageMap(document.getElementById('image-map'));
    imageMap.resize();
  };

  const getVideoIframe = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    if (doc.body.children[0].children['areaModal']) {
      return doc.body.children[0].children['areaModal'].getAttribute('data-iframe');
    } else {
      return '';
    }
  };

  const getVideoName = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    if (doc.body.children[0].children['areaModal']) {
      return doc.body.children[0].children['areaModal'].getAttribute('title');
    } else {
      return '';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.tickets.some((ticket) => ticket.isOwner === true)) {
        history.push('/home');
        return;
      }

      if (!props.preview && auth && auth.roomsFair) {
        const result = EventsServices.findRoomsFairType(
          auth.roomsFair,
          props.roomFairId,
          'access_fairs'
        );

        setRoomsFair(result ? result : null);
      }

      if (props.preview) {
        const result = await dispatch(
          EventsServices.findEventsPreview(props.eventId, history)
        );
        if (result && result.roomsFair && result.roomsFair.length > 0) {
          const stand = result.roomsFair.find(
            (item) =>
              item.roomFairId === Number(props.roomFairId) &&
              item.typeEvent === 'access_fairs'
          );
          if (stand) {
            setRoomsFair(stand ? stand : null);
          } else {
            history.push('/');
          }
        }
      }
    };

    if (!roomsFair) {
      fetchData().catch();
    }
  }, [dispatch, history, props, auth, roomsFair]);

  useEffect(() => {
    const idList = auth.roomsFair.reduce((idsArray, item) => {
      if (item.typeEvent === 'access_fairs') {
        for (let key in item) {
          if (key === 'roomFairId') {
            idsArray.push(item[key]);
          }
        }
      }
      return idsArray;
    }, []);

    const prev = idList.indexOf(parseInt(props.roomFairId)) - 1;
    const prox = idList.indexOf(parseInt(props.roomFairId)) + 1;

    setPrevious(prev >= 0 ? idList[prev] : null);
    setNext(prox < idList.length ? idList[prox] : null);
  }, [auth, props]);

  useEffect(() => {
    return () => (window.onresize = null);
  }, []);

  const openTab = (e, url) => {
    e.preventDefault();
    if (existUrl(url)) {
      window.open(url);
    }
  };

  const openTabLog = (e, url, tipo) => {
    e.preventDefault();
    LogServices.accessLog({
      eventId: auth.eventId,
      participantId: auth.participantId,
      roomId: roomsFair.roomId,
      standId: props.roomFairId,
      tipo: tipo,
      url: url,
      socketId: window.socket.id,
      isMobile: window.mobileCheck(),
    });
    if (existUrl(url)) {
      window.open(url);
    }
  };

  return (
    <TemplateApp>
      <div
        className="container-fluid"
        style={{ backgroundColor: 'var(--background-color)', paddingBottom: '50px' }}
      >
        <div className="container-fluid" id="mainCont">
          <div className="row">
            <div className="col-12 mt-30">
              <Link
                to={'/fair/' + previous}
                className="btn btn-dark"
                title="Anterior"
                onClick={(e) => {
                  const result = EventsServices.findRoomsFairType(
                    auth.roomsFair,
                    previous,
                    'access_fairs'
                  );
                  if (result) {
                    setRoomsFair(result ? result : null);
                  } else {
                    e.preventDefault();
                  }
                }}
                disabled={previous === null}
              >
                Anterior
              </Link>
              <Link
                to={'/fair/' + next}
                className="btn btn-dark pull-right"
                title="Próximo"
                onClick={(e) => {
                  const result = EventsServices.findRoomsFairType(
                    auth.roomsFair,
                    next,
                    'access_fairs'
                  );
                  if (result) {
                    setRoomsFair(result ? result : null);
                  } else {
                    e.preventDefault();
                  }
                }}
                disabled={next === null}
              >
                Próximo
              </Link>
            </div>
          </div>
          <div className="row">
            {roomsFair && roomsFair.tipo_stand === '3D' ? (
              <>
                <div className="col-xl-9 col-lg-8 col-md-12" id="col3DHolder">
                  <img
                    id="standImg"
                    useMap="#image-map"
                    src={roomsFair.url_stand_3d}
                    alt={roomsFair.roomFairName}
                    onLoad={handleResize}
                  />
                  {Parser(roomsFair.mapa_3D)}
                  <h5 className="roomTitle">{roomsFair.roomFairName}</h5>
                </div>

                <div
                  className="modal fade"
                  id="standModal"
                  tabIndex="-1"
                  aria-labelledby="standModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="standModalLabel">
                          {getVideoName(roomsFair.mapa_3D)}
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Fechar"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="embed-responsive embed-responsive-16by9">
                          <div id="playerModal" className="embed-responsive-item">
                            {Parser(getVideoIframe(roomsFair.mapa_3D))}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-dismiss="modal"
                        >
                          Fechar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-xl-9 col-lg-8 col-md-12" id="colVideoHolder">
                <div className="embed-responsive embed-responsive-16by9">
                  <div id="playerStand" className="embed-responsive-item">
                    {Parser((roomsFair && roomsFair.urlStreaming) || '')}
                  </div>
                </div>
                <h5 className="roomTitle">
                  {roomsFair !== null ? roomsFair.roomFairName : ''}
                </h5>
              </div>
            )}
            {roomsFair ? (
              <div
                className="col-xl-3 col-lg-4 col-md-12"
                style={{ height: 'fit-content', padding: '0.75rem' }}
              >
                <InteratividadesStands
                  roomsFair={roomsFair}
                  openTab={openTab}
                  openTabLog={openTabLog}
                  preview={props.preview}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </TemplateApp>
  );
};

export default FairEvents;
